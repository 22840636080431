:root {
  --color-transparent-hover: rgb(0 0 0 / 0.03);

  --color-background: 255 255 255;
  --color-background-alt: 251 251 251;
  --color-background-alt2: 23 92 219;
  --color-background-alt3: 18 82 163;
  --color-background-alt4: 13 60 119;

  --color-primary-300: 103 149 232;
  --color-primary-500: 23 93 220;
  --color-primary-700: 18 82 163;

  --color-secondary-100: 240 240 240;
  --color-secondary-300: 206 212 220;
  --color-secondary-500: 137 146 159;
  --color-secondary-700: 33 37 41;

  --color-success-500: 1 126 69;
  --color-success-700: 0 85 46;

  --color-danger-500: 200 53 34;
  --color-danger-700: 152 41 27;

  --color-warning-500: 139 102 9;
  --color-warning-700: 105 77 5;

  --color-info-500: 85 85 85;
  --color-info-700: 59 58 58;

  --color-text-main: 33 37 41;
  --color-text-muted: 109 117 126;
  --color-text-contrast: 255 255 255;
  --color-text-alt2: 255 255 255;
  --color-text-code: 192 17 118;

  --tw-ring-offset-color: #ffffff;
}

.theme_light {
  /* should be left empty as white is the default */
}

.theme_dark {
  --color-transparent-hover: rgb(255 255 255 / 0.12);

  --color-background: 31 36 46;
  --color-background-alt: 22 28 38;
  --color-background-alt2: 47 52 61;
  --color-background-alt3: 47 52 61;
  --color-background-alt4: 16 18 21;

  --color-primary-300: 23 93 220;
  --color-primary-500: 106 153 240;
  --color-primary-700: 180 204 249;

  --color-secondary-100: 47 52 61;
  --color-secondary-300: 110 118 137;
  --color-secondary-500: 186 192 206;
  --color-secondary-700: 255 255 255;

  --color-success-500: 82 224 124;
  --color-success-700: 168 239 190;

  --color-danger-500: 255 141 133;
  --color-danger-700: 255 191 187;

  --color-warning-500: 255 235 102;
  --color-warning-700: 255 245 179;

  --color-info-500: 164 176 198;
  --color-info-700: 209 215 226;

  --color-text-main: 255 255 255;
  --color-text-muted: 186 192 206;
  --color-text-contrast: 25 30 38;
  --color-text-alt2: 255 255 255;
  --color-text-code: 240 141 199;

  --tw-ring-offset-color: #1f242e;
}

@import "./search/search.component.css";
